import styled, { css } from 'styled-components';

export const URLPath = styled.ul`
  display: flex;
  align-items: center;

  > li {
    font-size: 1.25rem;
    color: #b5b5c3;
    list-style: none;

    font-weight: 600;

    a {
      color: inherit;
      text-decoration: inherit;

      transition: color 0.2s;

      &:hover {
        color: #c0504d;
      }
    }

    & + li {
      margin-left: 15px;
    }

    &:nth-child(1) {
      font-size: 28px;

      color: #181c32;
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const ChooseAFile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0;

  > div {
    display: flex;
    align-items: center;

    > label,
    > input {
      cursor: pointer;
    }

    > label {
      margin-right: 6px;
    }
  }
`;

export const InputFees = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0px;
`;
