import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  max-width: 851px;
  width: 100%;

  background-color: #f3f6f9;

  border-radius: 10px;

  /* padding-bottom: 20px; */

  @media screen and (max-width: 1365px) {
    max-width: 717px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 1140px) {
    max-width: 100%;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px 30px;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 30px;
`;

export const UsersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 414px;
  width: 100%;

  background-color: #02ffd1;

  border: 1px solid #02ffd1;
  border-radius: 10px;

  padding-bottom: 46px;

  @media screen and (max-width: 1365px) {
    max-width: 345px;
  }

  @media screen and (max-width: 1140px) {
    max-width: 100%;
  }
`;

export const UsersListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 70px;

  padding: 0 29px;

  > h1 {
    font-size: 1.15rem;
    color: #fff;
  }

  > div:nth-child(2) {
    > button {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 33px;
      width: 33px;

      border-radius: 13px;
    }
  }
`;

export const UsersList = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  list-style: none;

  padding: 0 25px;

  width: 100%;
`;

export const UserListItem = styled.li`
  display: flex;
  align-items: center;

  width: 100%;
  height: 43px;

  & + li {
    margin-top: 33px;
  }

  > div:nth-child(1) {
    width: 40px;
    height: 40px;

    background-color: #fff;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    border-radius: 10px;

    margin-right: 16px;

    > img {
      width: 30px;
      height: 30px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    height: 100%;

    > a {
      font-size: 1.1rem;
      color: #f2f1f0;
      text-decoration: none;
      font-weight: 700;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f2f1f0')};
        text-decoration: none;
      }
    }

    > span {
      color: #ccc;
      font-size: 0.95rem;
    }
  }
`;

export const News = styled.div`
  width: calc(100% / 4 - 24px);

  background-color: white;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  border: 1px solid #f1f1f1;
  border-radius: 10px;
`;

export const NewsImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 100%;
    height: auto;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const NewsInfo = styled.div`
  padding: 10px;

  > a {
    color: #181c32;

    font-size: 18px;
    font-weight: 700;

    transition: color 0.2s;

    &:hover {
      color: #02ffd1;
    }
  }

  > p {
    color: #7e8299;
    font-weight: 600;

    margin-top: 4px;
    margin-bottom: 8px;
  }

  > span {
    color: #aaa;

    display: flex;
    align-items: center;

    > svg {
      margin-right: 8px;
    }
  }
`;

export const SimulationContainer = styled.div`
  max-width: 414px;
  width: 100%;

  border-radius: 10px;
  border: 0;

  padding-bottom: 48px;

  background-color: #eee5ff;
`;

export const SimulationHeader = styled.div`
  padding: 0 29px;

  display: flex;
  align-items: center;

  height: 70px;

  > h1 {
    color: #181c32;

    font-size: 16px;
  }
`;

export const SimulationContent = styled.div`
  padding: 0 29px;

  > p {
    margin: 5px 0 16px;

    font-size: 16px;

    color: #408401;

    text-align: center;
  }
`;
