import { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiMinusCircle, FiUpload } from 'react-icons/fi';
import { CSSObject } from 'styled-components';

import { Form } from '@components/elements/Form';
import { Master } from '@components/layouts/Master';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Textarea } from '@components/elements/Form/Textarea';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  InputFile,
  InputFileLabel,
  InputFileSpan,
  FilesContainer,
} from './styles';

interface IRouteParams {
  id: string;
}

interface ICredit {
  commentaries: string;
  id: string;
}

interface IInputFileLabelElement extends EventTarget {
  files: FileList;
  name: string;
}

interface IElement extends Element {
  style: CSSObject;
  value: string;
}

interface IFile {
  name: string;
  file: File;
}

const ClientCreditDetails: FC = () => {
  const [credit, setCredit] = useState<ICredit>();
  const [inputFiles, setInputFiles] = useState<JSX.Element[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);

  const filesContainerRef = useRef<HTMLDivElement>(null);
  const fileLabelRef = useRef<HTMLDivElement>(null);
  const { params } = useRouteMatch<IRouteParams>();
  const { id } = params;
  const { addToast } = useToast();

  useEffect(() => {
    api.get(`/credits/${id}`).then(response => {
      setCredit(response.data.credit);
    });
  }, [id]);

  const generateIds = useCallback((length: number) => {
    const result: string[] = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;

    Array.from(Array(length).keys()).forEach(_ => {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength)),
      );
    });

    return result.join('');
  }, []);

  const handleInputFileChange = useCallback(
    (event: FormEvent<HTMLLabelElement>, labelId: string) => {
      const target = event.target as IInputFileLabelElement;
      const svg = document.querySelector<IElement>(
        `#${labelId} > svg`,
      ) as IElement;
      const fileSpan = document.querySelector<IElement>(
        `#${labelId} > span`,
      ) as IElement;
      const inputFile = document.querySelector<IElement>(
        `#${labelId} > span`,
      ) as IElement;

      const fileSize = target.files[0].size * 0.001 * 0.001;
      const fileExtension = target.files[0].name.split('.')[1];

      if (fileExtension !== 'pdf' && fileExtension !== 'jpeg') {
        addToast({
          type: 'error',
          title: 'Arquivo inválido!',
          message: 'São aceitos apenas arquivos com extensão .JPEG e .PDF!',
        });
        inputFile.value = '';
        return;
      }

      if (Number(fileSize.toFixed(2)) > 2.7) {
        addToast({
          type: 'error',
          title: 'Arquivo muito grande!',
          message: 'O tamanho máximo de arquivos válidos é de 2MB!',
        });
        inputFile.value = '';
        return;
      }

      svg.style.color = '#c0504d';
      fileSpan.style.color = '#181c32';
      fileSpan.innerHTML = target.files[0].name;

      setFiles([...files, { name: target.name, file: target.files[0] }]);
    },
    [addToast, files],
  );

  const removeFilesFromContainer = useCallback((key: string) => {
    setInputFiles(oldState => {
      const updatedState = oldState.filter(item => item.key !== key);

      return updatedState;
    });
  }, []);

  const fileRow = useCallback(
    (index: number, labelId: string): JSX.Element => {
      return (
        <FormRow key={labelId}>
          <InputFileLabel
            id={labelId}
            onChange={e => handleInputFileChange(e, labelId)}
          >
            <FiUpload size={24} color="#ccc" />
            <InputFileSpan>Selecione um arquivo</InputFileSpan>
            <InputFile
              type="file"
              name={`file[${index}]`}
              id={`file[${index}]`}
            />
          </InputFileLabel>

          <Input
            name={`title[${index}]`}
            id={`title[${index}]`}
            placeholder="Título do arquivo"
          />

          <Button
            styleType="info"
            type="button"
            icon={FiMinusCircle}
            style={{ alignSelf: 'center' }}
            onClick={() => removeFilesFromContainer(labelId)}
          />
        </FormRow>
      );
    },
    [handleInputFileChange, removeFilesFromContainer],
  );

  const addFilesToFilesContainer = useCallback(() => {
    const index = filesContainerRef.current?.children.length as number;
    const labelId = generateIds(7);

    setInputFiles([...inputFiles, fileRow(index + 1, labelId)]);

    addToast({
      title: 'Novo arquivo adicionado!',
      type: 'info',
    });
  }, [fileRow, generateIds, inputFiles, addToast]);

  const handleFormSubmit = useCallback(
    async data => {
      try {
        const formData = new FormData();

        files.forEach(({ file }, index) => {
          formData.append('file', file);
          formData.append(`title[${index}]`, data.title[index]);
        });

        formData.append('creditId', credit?.id || '');

        await api.post('/credit-files', formData);

        addToast({
          title: 'Arquivos enviados com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              type: 'error',
              title: `Solicitação não processada!`,
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu',
              type: 'error',
              message:
                'Um erro desconhecido aconteceu, por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [files, addToast, credit],
  );

  return (
    <>
      <Master>
        <Row>
          <URLPath>
            <li>Crédito</li>
            <li>{'>'}</li>
            <li>Detalhes</li>
          </URLPath>
        </Row>

        <Row>
          <Card>
            <CardHeader>
              <h1>Detalhes do meu crédito</h1>
            </CardHeader>

            <CardContent>
              <Form
                onSubmit={() => {
                  /* CODE */
                }}
              >
                <FormRow separator>
                  <h1>Comentários do administrador</h1>
                </FormRow>

                <FormRow>
                  <InputGroup textarea>
                    <label>Comentários</label>
                    <Textarea
                      name="commentaries"
                      readOnly
                      defaultValue={credit?.commentaries}
                      rows={20}
                      cols={10}
                    />
                  </InputGroup>
                </FormRow>
              </Form>
            </CardContent>
          </Card>
        </Row>

        <Row>
          <Card>
            <CardHeader>
              <h1>Upload de arquivos</h1>

              <button type="button" onClick={addFilesToFilesContainer}>
                Adicionar novo
              </button>
            </CardHeader>

            <CardContent>
              <Form onSubmit={handleFormSubmit}>
                <FormRow separator>
                  <h1>Arquivos</h1>
                </FormRow>

                <FormRow ref={fileLabelRef}>
                  <InputFileLabel
                    id="file-label"
                    onChange={e => handleInputFileChange(e, 'file-label')}
                  >
                    <FiUpload size={24} color="#ccc" />
                    <InputFileSpan>Selecione um arquivo</InputFileSpan>
                    <InputFile type="file" name="file[0]" id="file[0]" />
                  </InputFileLabel>

                  <Input
                    name="title[0]"
                    id="title[0]"
                    placeholder="Título do arquivo"
                  />
                </FormRow>

                <FilesContainer ref={filesContainerRef}>
                  {inputFiles.map(inputFile => inputFile)}
                </FilesContainer>

                <FormRow buttonWrapper style={{ marginTop: 20 }}>
                  <Button styleType="success" type="submit">
                    Enviar arquivos
                  </Button>
                </FormRow>
              </Form>
            </CardContent>
          </Card>
        </Row>
      </Master>
    </>
  );
};

export { ClientCreditDetails };
