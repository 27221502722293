import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const PixConfirmation = styled.div`
  flex: 1;

  > header {
    padding-bottom: 15px;

    > h1 {
      font-size: 22px;
      color: #1e1e1e;
      margin-bottom: 6px;
    }

    > strong {
      font-size: 32px;
      color: #c0504d;
    }

    > p {
      margin-top: 6px;
      font-size: 18px;

      > span {
        font-weight: 500;
      }
    }
  }

  > main {
    > div {
      width: 100%;

      border-bottom: 1px solid #ccc;
      padding: 15px 8px;

      &:nth-child(1) {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }

      &:not(:nth-last-child(1)) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 2px;

        > span {
          font-size: 16px;
          color: #7a7b7f;
        }

        > strong {
          font-size: 18px;
          color: #1e1e1e;
        }
      }

      &:nth-last-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 2px;

        > div {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: space-between;

          > span:nth-child(1) {
            font-size: 16px;
            color: #7a7b7f;
          }

          > span:nth-child(2) {
            font-size: 18px;
            color: #1e1e1e;
          }
        }
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    margin-top: 20px;

    > button {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 516px) {
    > header {
      > h1 {
        font-size: 18px;
        margin-bottom: 3px;
      }

      > strong {
        font-size: 24px;
      }

      > p {
        font-size: 14px;
        margin-top: 3px;
      }
    }

    > main {
      > div {
        padding: 10px 5px;

        &:not(:nth-last-child(1)) {
          > span {
            font-size: 13px;
          }

          > strong {
            font-size: 15px;
          }
        }

        &:nth-last-child(1) {
          > div {
            > span:nth-child(1) {
              font-size: 13px;
            }

            > span:nth-child(2) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    > header {
      > h1 {
        font-size: 15px;
      }

      > strong {
        font-size: 20px;
      }

      > p {
        font-size: 12px;
      }
    }

    > main {
      > div {
        &:not(:nth-last-child(1)) {
          > span {
            font-size: 9px;
          }

          > strong {
            font-size: 11px;
          }
        }

        &:nth-last-child(1) {
          > div {
            > span:nth-child(1) {
              font-size: 9px;
            }

            > span:nth-child(2) {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
`;
