import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Toast } from '@components/alerts/Toast';

import GlobalStyles from './styles/global';
import { Routes } from './routes';
import { Providers } from './hooks';

const App: FC = () => {
  console.log(process.env);

  return (
    <>
      <BrowserRouter>
        <Providers>
          <Routes />
          <Toast />
        </Providers>
      </BrowserRouter>
      <GlobalStyles />
    </>
  );
};

export default App;
