import { v4 } from 'uuid';

import { AllTelemedicine } from '@pages/private/admin/BbankConnect/Telemedicine/All';
import { TelemedicineDetails } from '@pages/private/admin/BbankConnect/Telemedicine/Details';
import { DownloadUserFiles } from '@pages/private/admin/Downloads/User';
import { UserFiles } from '@pages/private/admin/Downloads/User/Files';
import { AllCredits } from '@pages/private/admin/CredCash/All';
import { CreatePFCredit } from '@pages/private/admin/CredCash/Create/PF';
import { CreatePJCredit } from '@pages/private/admin/CredCash/Create/PJ';
import { ListCreditsByStatus } from '@pages/private/admin/CredCash/ListByStatus';
import { UpdatePFCredit } from '@pages/private/admin/CredCash/UpdatePFCredit';
import { UpdatePJCredit } from '@pages/private/admin/CredCash/UpdatePJCredit';
import { DownloadCredCashFiles } from '@pages/private/admin/Downloads/CredCash';
import { CredCashFiles } from '@pages/private/admin/Downloads/CredCash/Files';
import { AllFaqs } from '@pages/private/admin/Faq/All';
import { NewFaq } from '@pages/private/admin/Faq/New';
import { AllFaqCategories } from '@pages/private/admin/FaqCategories/All';
import { NewFaqCategory } from '@pages/private/admin/FaqCategories/New';
import { AllUsers } from '@pages/private/admin/Users/All';
import { EditUser } from '@pages/private/admin/Users/Edit';
import { ListUsersByRoleName } from '@pages/private/admin/Users/ListByRoleName';
import { NewUser } from '@pages/private/admin/Users/New';
import { NewTelemedicine } from '@pages/private/admin/BbankConnect/Telemedicine/New';
import { ListMerchants } from '@pages/private/admin/BbankPay/Merchants/List';
import { ListByStatusNameMerchants } from '@pages/private/admin/BbankPay/Merchants/ListByStatusName';
import { NewMerchant } from '@pages/private/admin/BbankPay/Merchants/New';
import { DownloadMerchantFiles } from '@pages/private/admin/Downloads/Merchants';
import { MerchantFiles } from '@pages/private/admin/Downloads/Merchants/Files';
import { ListFranchisees } from '@pages/private/admin/Franchisees/All';
import { ListProspectsFromFranchisee } from '@pages/private/admin/Franchisees/Prospects';
import { MerchantTransactions } from '@pages/private/admin/BbankPay/Merchants/Transactions';
import { ListProspects } from '@pages/private/admin/Prospects/List';
import { ProspectDetails } from '@pages/private/admin/Prospects/Details';
import { CreateNews } from '@pages/private/admin/News/Create';
import { CreateDepartment } from '@pages/private/admin/Departments/Create';
import { ListDepartments } from '@pages/private/admin/Departments/List';
import { UpdateDepartment } from '@pages/private/admin/Departments/Update';
import { UpdateTickets } from '@pages/private/admin/Tickets/Update';
import { ListTicketsByDepartments } from '@pages/private/admin/Tickets/ListTicketsByDepartments';
import { ListDepartmentsForTickets } from '@pages/private/admin/Tickets/Departments';
import { ListTickets } from '@pages/private/admin/Tickets/List';
import { NewMarketingCategory } from '@pages/private/admin/Marketing/Categories/New';
import { ListMarketingCategories } from '@pages/private/admin/Marketing/Categories/List';
import { EditMarketingCategory } from '@pages/private/admin/Marketing/Categories/Edit';
import { ListCategoriesForFiles } from '@pages/private/admin/Marketing/Files/ListCategoriesForFiles';
import { ListFilesByCategoryId } from '@pages/private/admin/Marketing/Files/ListFilesByCategoryId';
import { AddMarketingFiles } from '@pages/private/admin/Marketing/Files/AddFiles';
import { ListBbankConnectResearchs } from '@pages/private/admin/BbankConnect/Telemedicine/Research/List';
import { TelemedicineResearchDetails } from '@pages/private/admin/BbankConnect/Telemedicine/Research/Details';
import { NewInvestmentPercentage } from '@pages/private/admin/BbankInvest/InvestmentsPercentages/New';
import { ListInvestmentsPercentages } from '@pages/private/admin/BbankInvest/InvestmentsPercentages/List';
import { EditInvestmentPercentage } from '@pages/private/admin/BbankInvest/InvestmentsPercentages/Edit';
import { NewInvestment } from '@pages/private/admin/BbankInvest/Investments/New';
import { ListInvestments } from '@pages/private/admin/BbankInvest/Investments/List';
import { NewConveniences } from '@pages/private/admin/BbankPay/Conveniences/New';
import { ListConveniences } from '@pages/private/admin/BbankPay/Conveniences/List';
import { ListByStatusNameConveniences } from '@pages/private/admin/BbankPay/Conveniences/ListByStatusName';
import { EditConveniences } from '@pages/private/admin/BbankPay/Conveniences/Edit';
import { ConvenienceTransactions } from '@pages/private/admin/BbankPay/Conveniences/Transactions';
import { LinksPerSite } from '@pages/private/admin/Links/Sites';
import { DownloadConvenienceFiles } from '@pages/private/admin/Downloads/Conveniences';
import { ConvenienceFiles } from '@pages/private/admin/Downloads/Conveniences/Files';
import { ListPropertyFinancing } from '@pages/private/admin/CredCash/Financing/Property/List';
import { FinancingDetails } from '@pages/private/admin/CredCash/Financing/Property/Details';
import { ListFilesFromFinancing } from '@pages/private/admin/CredCash/Financing/Property/Files/List';
import { ListConsignedProspects } from '@pages/private/admin/CredCash/Consigned/Prospects/List';
import { ConsignedProspectDetails } from '@pages/private/admin/CredCash/Consigned/Prospects/Details';
import { NewConsigned } from '@pages/private/admin/CredCash/Consigned/Credits/New';
import { ListConsigned } from '@pages/private/admin/CredCash/Consigned/Credits/List';
import { ConsignedDetails } from '@pages/private/admin/CredCash/Consigned/Credits/Details';
import { ListConsignedFiles } from '@pages/private/admin/CredCash/Consigned/Files/List';
import { ListConsignedCompanies } from '@pages/private/admin/CredCash/Consigned/Companies/List';
import { ConsignedCompanyDetails } from '@pages/private/admin/CredCash/Consigned/Companies/Details';
import { NewPlatform } from '@pages/private/admin/Platforms/New';
import { ListPlatforms } from '@pages/private/admin/Platforms/List';
import { EditPlatform } from '@pages/private/admin/Platforms/Edit';
import { NewTicket } from '@pages/private/admin/Tickets/New';
import { ListTicketsByUserId } from '@pages/private/admin/Tickets/ListTicketsByUserId';
import { TicketDetails } from '@pages/private/admin/Tickets/Details';
import { ListECommerce } from '@pages/private/admin/ECommerce/List';
import { ListECommerceFiles } from '@pages/private/admin/ECommerce/ListFiles';
import { EditECommerce } from '@pages/private/admin/ECommerce/Edit';
import { ListCreditsPerson } from '@pages/private/admin/CredCash/BotResearch/CreditsPerson/List';
import { ListCreditsCompany } from '@pages/private/admin/CredCash/BotResearch/CreditsCompany/List';
import { CreditPersonDetails } from '@pages/private/admin/CredCash/BotResearch/CreditsPerson/Details';
import { CreditCompanyDetails } from '@pages/private/admin/CredCash/BotResearch/CreditsCompany/Details';
import { Financeiro } from '@pages/private/admin/Financial/New';
import { ListFinanceiro } from '@pages/private/admin/Financial/List';
import { FinancialDetails } from '@pages/private/admin/Financial/Details';
import { TopBankMerchantForm } from '@pages/private/admin/BbankPay/TopBbank/Merchants/New';
import { TopBankListMerchants } from '@pages/private/admin/BbankPay/TopBbank/Merchants/List';
import { TopBankEditMerchant } from '@pages/private/admin/BbankPay/TopBbank/Merchants/Edit';
import { ListNews } from '@pages/private/admin/News/List';
import { CreateSliders } from '@pages/private/admin/News/Sliders/Create';
import { ListSliders } from '@pages/private/admin/News/Sliders/List';
import { EditInvestment } from '@pages/private/admin/BbankInvest/Investments/Edit';
import { ManageClientTaxes } from '@pages/private/admin/ClientTaxes/Manage';
import { ListTaxesGroups } from '@pages/private/admin/TaxesGroups/List';
import { TaxesGroupDetails } from '@pages/private/admin/TaxesGroups/Details';
import { CreateTaxesGroups } from '@pages/private/admin/TaxesGroups/Create';
import { AllUserBalances } from '@pages/private/admin/Users/Balance/All';

import { Route } from './Route';

const adminRoutes = [
  <Route
    key={v4()}
    path="/bbank-connect/telemedicine/new"
    isPrivate
    grantAccess={['admin']}
    component={NewTelemedicine}
  />,
  <Route
    key={v4()}
    path="/bbank-connect/telemedicine/all"
    isPrivate
    grantAccess={['admin']}
    component={AllTelemedicine}
  />,
  <Route
    key={v4()}
    path="/bbank-connect/telemedicine/details/:telemedicineId"
    isPrivate
    grantAccess={['admin']}
    component={TelemedicineDetails}
  />,

  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/user/files"
    component={DownloadUserFiles}
  />,
  <Route
    key={v4()}
    path="/user/:id/files"
    isPrivate
    grantAccess={['admin']}
    component={UserFiles}
  />,

  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin', 'credcash']}
    path="/credit/files"
    component={DownloadCredCashFiles}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/credit/:id/files"
    component={CredCashFiles}
  />,

  <Route
    key={v4()}
    isPrivate
    path="/users/new"
    grantAccess={['admin']}
    component={NewUser}
  />,
  <Route
    key={v4()}
    isPrivate
    path="/users/role"
    grantAccess={['admin']}
    component={ListUsersByRoleName}
  />,
  <Route
    key={v4()}
    isPrivate
    path="/users/all"
    grantAccess={['admin']}
    component={AllUsers}
  />,
  <Route
    key={v4()}
    isPrivate
    path="/users/view/:id"
    exact
    grantAccess={['admin']}
    component={EditUser}
  />,

  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/faq-categories/all"
    component={AllFaqCategories}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/faq-categories/new"
    component={NewFaqCategory}
  />,

  <Route
    key={v4()}
    isPrivate
    path="/faq/all"
    grantAccess={['admin']}
    component={AllFaqs}
  />,
  <Route
    key={v4()}
    isPrivate
    path="/faq/new"
    grantAccess={['admin']}
    component={NewFaq}
  />,

  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin', 'credcash']}
    path="/cred-cash/all"
    component={AllCredits}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin', 'credcash']}
    path="/cred-cash/pj/:id"
    component={UpdatePJCredit}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin', 'credcash']}
    path="/cred-cash/credits"
    component={ListCreditsByStatus}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin', 'credcash']}
    path="/cred-cash/pf/:id"
    component={UpdatePFCredit}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin', 'credcash']}
    path="/cred-cash/new/pf"
    component={CreatePFCredit}
    exact
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin', 'credcash']}
    path="/cred-cash/new/pj"
    component={CreatePJCredit}
  />,

  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/bbank-pay/merchants/all"
    component={ListMerchants}
  />,

  <Route
    key={v4()}
    path="/bbank-pay/merchants/new"
    isPrivate
    grantAccess={['admin']}
    component={NewMerchant}
  />,

  <Route
    key={v4()}
    path="/bbank-pay/top-bbank/view/:merchantId"
    isPrivate
    grantAccess={['admin']}
    component={TopBankEditMerchant}
  />,

  <Route
    key={v4()}
    path="/bbank-pay/merchants/status"
    isPrivate
    grantAccess={['admin']}
    component={ListByStatusNameMerchants}
  />,

  <Route
    key={v4()}
    path="/merchants/files"
    isPrivate
    grantAccess={['admin']}
    component={DownloadMerchantFiles}
  />,

  <Route
    key={v4()}
    path="/conveniences/files"
    isPrivate
    grantAccess={['admin']}
    component={DownloadConvenienceFiles}
  />,

  <Route
    key={v4()}
    path="/merchants/:merchantId/files"
    isPrivate
    grantAccess={['admin']}
    component={MerchantFiles}
  />,

  <Route
    key={v4()}
    path="/conveniences/:convenienceId/files"
    isPrivate
    grantAccess={['admin']}
    component={ConvenienceFiles}
  />,
  <Route
    key={v4()}
    path="/franchisees/all"
    isPrivate
    grantAccess={['admin']}
    component={ListFranchisees}
  />,

  <Route
    key={v4()}
    path="/bbank-pay/merchants/:merchantId/transactions"
    isPrivate
    grantAccess={['admin']}
    component={MerchantTransactions}
  />,
  <Route
    key={v4()}
    path="/prospects/list"
    isPrivate
    grantAccess={['admin']}
    component={ListProspects}
  />,
  <Route
    key={v4()}
    path="/prospects/:prospectId/view"
    isPrivate
    grantAccess={['admin']}
    component={ProspectDetails}
  />,
  <Route
    key={v4()}
    path="/news/create"
    isPrivate
    grantAccess={['admin']}
    component={CreateNews}
  />,
  <Route
    key={v4()}
    path="/departments/new"
    isPrivate
    grantAccess={['admin']}
    component={CreateDepartment}
  />,
  <Route
    key={v4()}
    path="/departments/list"
    isPrivate
    grantAccess={['admin']}
    component={ListDepartments}
  />,
  <Route
    key={v4()}
    path="/departments/:departmentId/view"
    isPrivate
    grantAccess={['admin']}
    component={UpdateDepartment}
  />,
  <Route
    key={v4()}
    path="/tickets/:ticketId/answer"
    isPrivate
    grantAccess={['admin']}
    component={UpdateTickets}
  />,
  <Route
    key={v4()}
    path="/tickets/departments/list"
    isPrivate
    grantAccess={['admin']}
    component={ListDepartmentsForTickets}
  />,
  <Route
    key={v4()}
    path="/tickets/departments/:departmentId/list"
    isPrivate
    grantAccess={['admin']}
    component={ListTicketsByDepartments}
  />,
  <Route
    key={v4()}
    path="/tickets/list"
    isPrivate
    grantAccess={['admin']}
    component={ListTickets}
  />,
  <Route
    key={v4()}
    path="/franchisees/:indicationId/prospects"
    isPrivate
    grantAccess={['admin']}
    component={ListProspectsFromFranchisee}
  />,
  <Route
    key={v4()}
    path="/marketing/categories/new"
    isPrivate
    grantAccess={['admin']}
    component={NewMarketingCategory}
  />,
  <Route
    key={v4()}
    path="/marketing/categories/list"
    isPrivate
    grantAccess={['admin']}
    component={ListMarketingCategories}
  />,
  <Route
    key={v4()}
    path="/marketing/categories/:marketingCategoryId/edit"
    isPrivate
    grantAccess={['admin']}
    component={EditMarketingCategory}
  />,
  <Route
    key={v4()}
    path="/marketing/categories/files"
    isPrivate
    grantAccess={['admin']}
    component={ListCategoriesForFiles}
  />,
  <Route
    key={v4()}
    path="/marketing/categories/:marketingCategoryId/files"
    isPrivate
    exact
    grantAccess={['admin']}
    component={ListFilesByCategoryId}
  />,
  <Route
    key={v4()}
    path="/marketing/categories/:marketingCategoryId/files/new"
    isPrivate
    grantAccess={['admin']}
    component={AddMarketingFiles}
  />,
  <Route
    key={v4()}
    path="/bbank-connect/researchs/list"
    isPrivate
    grantAccess={['admin']}
    component={ListBbankConnectResearchs}
  />,
  <Route
    key={v4()}
    path="/bbank-connect/researchs/:telemedicineScoreId/details"
    isPrivate
    grantAccess={['admin']}
    component={TelemedicineResearchDetails}
  />,
  <Route
    key={v4()}
    path="/bbank-invest/investments/percentages/new"
    isPrivate
    grantAccess={['admin']}
    component={NewInvestmentPercentage}
  />,
  <Route
    key={v4()}
    path="/bbank-invest/investments/percentages/:percentageId/edit"
    isPrivate
    grantAccess={['admin']}
    component={EditInvestmentPercentage}
  />,
  <Route
    key={v4()}
    path="/bbank-invest/investments/percentages/list"
    isPrivate
    grantAccess={['admin']}
    component={ListInvestmentsPercentages}
  />,
  <Route
    key={v4()}
    path="/bbank-invest/investments/new"
    isPrivate
    grantAccess={['admin']}
    component={NewInvestment}
  />,
  <Route
    key={v4()}
    path="/bbank-invest/investments/list"
    isPrivate
    grantAccess={['admin']}
    component={ListInvestments}
  />,
  <Route
    key={v4()}
    path="/bbank-pay/conveniences/new"
    isPrivate
    grantAccess={['admin']}
    component={NewConveniences}
  />,
  <Route
    key={v4()}
    path="/bbank-pay/conveniences/all"
    isPrivate
    grantAccess={['admin']}
    component={ListConveniences}
  />,
  <Route
    key={v4()}
    path="/bbank-pay/conveniences/view/:convenienceId"
    isPrivate
    grantAccess={['admin']}
    component={EditConveniences}
  />,
  <Route
    key={v4()}
    path="/bbank-pay/conveniences/status"
    isPrivate
    grantAccess={['admin']}
    component={ListByStatusNameConveniences}
  />,
  <Route
    key={v4()}
    path="/bbank-pay/conveniences/:convenienceId/transactions"
    isPrivate
    grantAccess={['admin']}
    component={ConvenienceTransactions}
  />,
  <Route
    key={v4()}
    path="/links/sites"
    isPrivate
    grantAccess={['admin']}
    component={LinksPerSite}
  />,
  <Route
    key={v4()}
    path="/credcash/financing/property/list"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListPropertyFinancing}
  />,
  <Route
    key={v4()}
    path="/cred-cash/financing/property/:financingId/details"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={FinancingDetails}
  />,
  <Route
    key={v4()}
    path="/cred-cash/financing/property/:financingId/files"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListFilesFromFinancing}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/prospects/all"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListConsignedProspects}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/prospects/:consignedProspectId/details"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ConsignedProspectDetails}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/new"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={NewConsigned}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/list"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListConsigned}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/:consignedId/details"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ConsignedDetails}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/:consignedId/files"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListConsignedFiles}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/companies/list"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListConsignedCompanies}
  />,
  <Route
    key={v4()}
    path="/cred-cash/consigned/companies/:consignedCompanyDocument/details"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ConsignedCompanyDetails}
  />,
  <Route
    key={v4()}
    path="/platforms/new"
    isPrivate
    grantAccess={['admin']}
    component={NewPlatform}
  />,
  <Route
    key={v4()}
    path="/platforms/list"
    isPrivate
    grantAccess={['admin']}
    component={ListPlatforms}
  />,
  <Route
    key={v4()}
    path="/platforms/:platformId/edit"
    isPrivate
    grantAccess={['admin']}
    component={EditPlatform}
  />,
  <Route
    key={v4()}
    path="/tickets/new"
    isPrivate
    grantAccess={['admin']}
    component={NewTicket}
  />,
  <Route
    key={v4()}
    path="/tickets/me"
    isPrivate
    grantAccess={['admin']}
    component={ListTicketsByUserId}
  />,
  <Route
    key={v4()}
    path="/tickets/:ticketId/details"
    isPrivate
    grantAccess={['admin']}
    component={TicketDetails}
  />,
  <Route
    key={v4()}
    path="/ecommerce/list"
    isPrivate
    grantAccess={['admin']}
    component={ListECommerce}
  />,
  <Route
    key={v4()}
    path="/ecommerce/:ecommerceId/files"
    isPrivate
    grantAccess={['admin']}
    component={ListECommerceFiles}
  />,
  <Route
    key={v4()}
    path="/ecommerce/:ecommerceId/edit"
    isPrivate
    grantAccess={['admin']}
    component={EditECommerce}
  />,
  <Route
    key={v4()}
    path="/bots/credits/person/list"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListCreditsPerson}
  />,
  <Route
    key={v4()}
    path="/bots/credits/company/list"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={ListCreditsCompany}
  />,
  <Route
    key={v4()}
    path="/bots/credits/person/:creditPersonId/details"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={CreditPersonDetails}
  />,
  <Route
    key={v4()}
    path="/bots/credits/company/:creditCompanyId/details"
    isPrivate
    grantAccess={['admin', 'credcash']}
    component={CreditCompanyDetails}
  />,

  <Route
    key={v4()}
    path="/Financial/New"
    isPrivate
    grantAccess={['admin']}
    component={Financeiro}
  />,
  <Route
    key={v4()}
    path="/financial/:financingReportId/details"
    isPrivate
    grantAccess={['admin']}
    component={FinancialDetails}
  />,
  <Route
    key={v4()}
    path="/Financial/List"
    isPrivate
    grantAccess={['admin']}
    component={ListFinanceiro}
  />,
  <Route
    key={v4()}
    path="/BbankPay/TopBank/New"
    isPrivate
    grantAccess={['admin']}
    component={TopBankMerchantForm}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/bbank-pay/top-bank/merchants/all"
    component={TopBankListMerchants}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/news/list"
    component={ListNews}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/news/sliders/create"
    component={CreateSliders}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/news/sliders/list"
    component={ListSliders}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/bbank-invest/investments/:investmentId/edit"
    component={EditInvestment}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/taxes/users/:id"
    component={ManageClientTaxes}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['admin']}
    path="/taxes-groups/list"
    component={ListTaxesGroups}
  />,
  <Route
    isPrivate
    grantAccess={['admin']}
    path="/taxes-groups/details/:id"
    component={TaxesGroupDetails}
  />,
  <Route
    isPrivate
    grantAccess={['admin']}
    path="/taxes-groups/create"
    component={CreateTaxesGroups}
  />,
  <Route
    isPrivate
    grantAccess={['admin']}
    path="/users/balances"
    component={AllUserBalances}
  />,
];

export { adminRoutes };
