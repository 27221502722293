import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { FiMoreHorizontal, FiPlusCircle, FiTrash } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { INew } from './interfaces';

export const ListNews: FC = () => {
  const [news, setNews] = useState<INew[]>();

  useEffect(() => {
    async function loadListNew() {
      const { data } = await api.get<any[]>('/news');

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<INew>(item),
      );

      setNews(parsedData);
    }

    const timer = setTimeout(() => loadListNew(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleDeletenews = useCallback(async (newsId: string) => {
    await api.delete(`/news/${newsId}`);

    setNews(oldState => {
      if (!oldState) {
        return [];
      }

      const updatedState = oldState.filter(newNews => newNews.id !== newsId);

      return updatedState;
    });
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da noticia',
        selector: 'title',
      },

      {
        name: 'Data de criação',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!news) {
      return [];
    }

    const data = news.map(platform => {
      return {
        id: platform.id,
        title: platform.title,
        search: platform.name,
        createdAt: format(new Date(platform.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="danger"
              onDoubleClick={() => handleDeletenews(platform.id)}
              title="Clique duas vezes para deletar!"
              icon={FiTrash}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [news, handleDeletenews]);

  return (
    <>
      {!news ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <Card>
              <CardHeader>
                <h1>Noticias registradas</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#c0504d' }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <Link to="/news/create">
                      <FiPlusCircle />
                      Nova Noticia
                    </Link>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
