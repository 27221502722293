import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { FiDownload, FiEye, FiMoreHorizontal } from 'react-icons/fi';
import { PDFDocument } from 'pdf-lib';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { Badge } from '@components/elements/Badge';
import { Textarea } from '@components/elements/Form/Textarea';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import bbanking from '@assets/img/passToPayLogo.png';

import { IBillet, IParams } from './interfaces';

export const BilletDetails: FC = () => {
  const { billetId } = useParams<IParams>();

  const [billet, setBillet] = useState<IBillet>();

  useEffect(() => {
    async function loadBillet() {
      const { data } = await api.get(`/billets/${billetId}`, {
        params: {
          relations: ['assignor', 'payer'],
        },
      });

      const parsedData = parseObjectPropertiesToCamelCase<IBillet>(data);

      setBillet(parsedData);
    }

    const timer = setTimeout(() => loadBillet(), 1500);

    return () => clearInterval(timer);
  }, [billetId]);

  const handleFormSubmit = useCallback(() => {
    window.open(billet?.billetUrl || '');
  }, [billet]);

  const [pdfData, setPdfData] = useState<string>();

  useEffect(() => {
    async function loadingPdf() {
      try {
        const response = await api.get(`/billets/pdf/${billetId}`, {
          responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);

        setPdfData(pdfUrl);
      } catch (error) {
        console.log('Erro ao carregar o PDF', error);
      }
    }

    loadingPdf();
  }, [billetId]);

  const handleDownloadPdf = async () => {
    if (pdfData) {
      try {
        const existingPdfBytes = await fetch(pdfData).then(res =>
          res.arrayBuffer(),
        );

        const imageBytes = await fetch(bbanking).then(res => res.arrayBuffer());

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const image = await pdfDoc.embedPng(imageBytes);
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];

        const imageWidth = 100;
        const imageHeight = (imageWidth * image.height) / image.width;

        firstPage.drawImage(image, {
          x: 20,
          y: firstPage.getHeight() - imageHeight - 20,
          width: imageWidth,
          height: imageHeight,
          opacity: 1,
        });

        const pdfBytes = await pdfDoc.save();

        const modifiedBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        const modifiedPdfUrl = URL.createObjectURL(modifiedBlob);

        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = modifiedPdfUrl;
        link.download = 'boleto_passtopay.pdf';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(modifiedPdfUrl);
      } catch (error) {
        console.log('Erro ao personalizar o PDF', error);
      }
    } else {
      console.log('Dados do PDF não disponíveis');
    }
  };

  return (
    <>
      {!billet ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Boletos', 'Detalhes do boleto']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes de um boleto</h1>

                <Dropdown
                  styles={{
                    textColor: '#fff',
                    togglerBackground: '#02ffd1',
                  }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button type="button" onClick={handleDownloadPdf}>
                      <FiDownload />
                      Baixar comprovante
                    </button>
                  </li>
                </Dropdown>
                {billet.billetUrl && billet.billetUrl !== 'NO_URL_AVAILABLE' && (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <Dropdown
                      styles={{
                        textColor: '#fff',
                        togglerBackground: '#c0504d',
                      }}
                      icon={FiMoreHorizontal}
                    >
                      <li>
                        <a
                          href={billet.billetUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FiEye />
                          Visualizar o boleto completo
                        </a>
                      </li>
                    </Dropdown>
                  </div>
                )}
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} initialData={billet}>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <Badge type="info">
                      Emitido em&nbsp;
                      {format(
                        parseISO(billet.createdAt),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </Badge>
                  </div>

                  <FormRow>
                    <InputGroup>
                      <label>Emissor</label>
                      <Input name="assignor.name" disabled />
                    </InputGroup>

                    <InputGroup>
                      <label>Pagador</label>
                      <Input name="payer.name" disabled />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Código de barras</label>
                      <Input name="barCode" disabled />
                    </InputGroup>

                    <InputGroup>
                      <label>Linha digitável</label>
                      <Input name="insertLine" disabled />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Relação</label>
                      <Textarea name="comments" rows={10} readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
