import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FiMoreHorizontal, FiPlusCircle, FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Dropdown } from '@components/elements/Dropdown';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import useBank from '@hooks/bank';

import { bankApi } from '@services/bankApi';

import { IPayment } from './interfaces';
import { Card, CardHeader, CardContent } from '../../Transfer/New/styles';

export const ListPayments: FC = () => {
  const { push } = useHistory();
  const { handleVerifyToken } = useBank();

  const [payments, setPayments] = useState<IPayment[]>([]);

  const getPayments = useCallback(async () => {
    const { data } = await bankApi.get('/bill-payments');

    setPayments(data);
  }, []);

  useEffect(() => {
    handleVerifyToken().then(response => {
      if (response) {
        getPayments();
      }
    });
  }, [handleVerifyToken, getPayments]);

  const tableData = useMemo(() => {
    return payments.map(item => {
      return {
        integration_id: item.integration_id,
        insert_line: item.insert_line,
        bar_code: item.bar_code,
        created_at: format(parseISO(item.created_at), 'dd/MM/yyyy HH:mm:ss'),
        scheduled: item.scheduled
          ? format(parseISO(item.scheduled), 'dd/MM/yyyy HH:mm:ss')
          : 'Não agendado',
        details: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              icon={FiEye}
              title="Gerar código!"
              onClick={() =>
                push(
                  `/shared/bwallet/billets/bill-payments/${item._id}/details`,
                )
              }
            />
          </TableButtonContainer>
        ),
      };
    });
  }, [payments, push]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'ID de integração',
        selector: 'integration_id',
      },
      {
        name: 'Linha digitável',
        selector: 'insert_line',
      },
      {
        name: 'Código de barras',
        selector: 'bar_code',
      },
      {
        name: 'Criado em',
        selector: 'created_at',
      },
      {
        name: 'Agendado para',
        selector: 'scheduled',
      },
      {
        name: 'Ver boleto',
        selector: 'details',
      },
    ];
  }, []);

  return (
    <>
      <AuthModal onAuth={getPayments} />

      {!payments ? (
        <LoadingPage />
      ) : (
        <>
          <URLPath paths={['PEBQ', 'Pagamento de contas', 'Meus pagamentos']} />

          <Card>
            <CardHeader>
              <h1>Meus pagamentos</h1>

              <Dropdown
                styles={{ textColor: '#fff', togglerBackground: '#c0504d' }}
                icon={FiMoreHorizontal}
              >
                <li>
                  <button
                    type="button"
                    onClick={() =>
                      push('/shared/bwallet/bill-payments/new-payment')
                    }
                  >
                    <FiPlusCircle />
                    Novo pagamento
                  </button>
                </li>
              </Dropdown>
            </CardHeader>

            <CardContent>
              <DataTable data={tableData} columns={tableColumns} />
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
