import { FiLink } from 'react-icons/fi';
import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { v4 } from 'uuid';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent, Site } from './styles';

const LinksPerSite: FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [nickname, setNickname] = useState<string | undefined>();

  useEffect(() => {
    async function loadMe() {
      const { data } = await api.get(`/users/${user.id}`);

      setNickname(data.nickname || '');
    }

    const timer = setTimeout(() => loadMe(), 1500);

    return () => clearTimeout(timer);
  }, [user.id]);

  const handleCopyLink = useCallback(
    (link: string, urlParams?: boolean) => {
      if (urlParams) {
        navigator.clipboard.writeText(`${link}/indication/${nickname}`);

        addToast({
          title: 'Copiado para a área de transferência!',
          type: 'success',
        });

        return;
      }

      navigator.clipboard.writeText(`${link}?indication=${nickname}`);

      addToast({
        title: 'Copiado para a área de transferência!',
        type: 'success',
      });
    },
    [nickname, addToast],
  );

  const sites = useMemo(() => {
    return {
      'Bbanking Franquia': '//franquia.bbanking.com.br',
      'Bbanking Pague Tudo': '//paguetudo.bbankpay.com.br',
      'Bbank Connect': '//bbankconnect.com.br/formulario.html',
      'BBank Pay': '//forms.bbankpay.com.br',
      'Bbank Invest Imoveis': '//bbankinvest.com.br/imoveis/',
      'Bbank Invest': '//bbankinvest.com.br',

      // 'Crédito Emergencial': '//creditoemergencial.bbanking.com.br',
      // 'Consignado privado': '//privado.credcash.com.br',
    };
  }, []);

  return (
    <>
      {!nickname ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Links', 'Sites']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Sites para publicidade</h1>
              </CardHeader>

              <CardContent>
                {Object.entries(sites).map(([siteName, siteLink]) => (
                  <Site onClick={() => handleCopyLink(siteLink)} key={v4()}>
                    <FiLink size={30} color="#c0504d" />
                    <h1>{siteName}</h1>
                    <p>Clique aqui e copie o seu link</p>
                  </Site>
                ))}

                {/* <Site
                    onClick={() =>
                      handleCopyLink('https://dashboard.bbanking.com.br', true)
                    }
                  >
                    <FiLink size={30} color="#c0504d" />
                    <h1>Dashboard Bbanking</h1>
                    <p>Clique aqui e copie o seu link</p>
                  </Site> */}
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { LinksPerSite };
