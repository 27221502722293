import {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { format } from 'date-fns';
import { v4 } from 'uuid';
import Loader from 'react-loader-spinner';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { getCardFlag } from '@helpers/getCardFlag';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  DatePickerContainer,
  InputDate,
  LoaderContainer,
  ImgBrandContainer,
  ImgBrand,
} from './styles';
import { ITransaction } from './interfaces';

const MerchantTransactions: FC = () => {
  const [initialDate, setInitialDate] = useState(
    format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'),
  );
  const [, setFinalDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [transactions, setTransactions] = useState<ITransaction[]>();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setLoadingData(true);

    async function getDataFromApi() {
      setTransactions([]);
    }

    const timer = setTimeout(async () => {
      await getDataFromApi();
      setLoadingData(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleInputDateBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>, type: 'date-init' | 'date-final') => {
      const { value } = e.target;

      if (type === 'date-final') {
        setFinalDate(value);
      } else {
        setInitialDate(value);
      }
    },
    [],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Comprador',
        selector: 'search',
      },
      {
        name: 'Banderia/Cartão',
        selector: 'flag',
      },
      {
        name: 'Serviço',
        selector: 'service',
      },
      {
        name: 'Data',
        selector: 'date',
      },
      {
        name: 'Valor base',
        selector: 'valueBase',
      },
      {
        name: 'Valor cobrado',
        selector: 'valueCharged',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    const data = transactions?.map(item => {
      const imgBrand = getCardFlag(item.brand);
      const id = v4();
      const numberFormat = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format;

      return {
        id,
        search: item.establishment,
        flag: (
          <ImgBrandContainer>
            <ImgBrand src={imgBrand} alt={item.card} />
            {item.card}
          </ImgBrandContainer>
        ),
        service: item.service,
        date: format(new Date(item.date), 'dd/MM/yyyy'),
        valueBase: numberFormat(item.baseValue),
        valueCharged: numberFormat(item.chargedValue),
      };
    });

    return data || [];
  }, [transactions]);

  return (
    <>
      <Header />

      <Aside />

      <Main>
        <CardMenuContainer />

        {!transactions ? (
          <LoadingPage />
        ) : (
          <>
            <Row>
              <URLPath>
                <li>BbankPay</li>
                <li>{'>'}</li>
                <li>Estabelecimentos</li>
                <li>{'>'}</li>
                <li>Transações</li>
              </URLPath>
            </Row>

            <Row>
              <Card>
                <CardHeader>
                  <h1>Transações</h1>

                  <DatePickerContainer>
                    <span>De</span>
                    <InputDate
                      type="date"
                      onBlur={e => handleInputDateBlur(e, 'date-init')}
                      defaultValue={initialDate}
                    />
                    <span>até</span>
                    <InputDate
                      type="date"
                      onBlur={e => handleInputDateBlur(e, 'date-final')}
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                    />
                  </DatePickerContainer>
                </CardHeader>

                <CardContent>
                  {loadingData ? (
                    <LoaderContainer>
                      <Loader
                        type="TailSpin"
                        height={60}
                        width={60}
                        color="#c0504d"
                      />
                    </LoaderContainer>
                  ) : (
                    <DataTable
                      columns={tableColumns}
                      data={tableData}
                      subHeaderComponent
                    />
                  )}
                </CardContent>
              </Card>
            </Row>
          </>
        )}
      </Main>

      <Footer />
    </>
  );
};

export { MerchantTransactions };
