import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface IVisibilityProps {
  isVisible: boolean;
}

export const Container = styled.div`
  max-width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  button {
    padding: 10px 14px;

    background-color: #02ffd1;
    color: white;

    transition: background-color 0.2s;

    &:hover {
      background-color: ${shade(0.25, '#c0504d')};
    }
  }
`;

export const Content = styled.div<IVisibilityProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);

  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);

  z-index: 21;

  max-width: 500px;
  width: 100%;

  border: none;
  border-radius: 10px;

  background-color: white;

  visibility: hidden;
  opacity: 0;

  transition: visibility 0.5s, opacity 0.5s, transform 0.8s;

  ${props =>
    props.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -50%);
    `}

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 65px;

    padding: 0 23px;

    border-bottom: 1px solid #ebedf3;

    > h1 {
      font-size: 18px;
      font-weight: 600;
    }

    > svg {
      color: #ebedf3;

      cursor: pointer;

      transition: color 0.2s;

      &:hover {
        color: #c0504d;
      }
    }
  }

  > div:nth-child(2) {
    padding: 23px;

    max-height: 80vh;

    overflow: auto;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    p {
      margin-bottom: 13px;
      font-size: 1rem;
      color: #3f4254;
    }
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;

    padding: 15px 23px;

    border-top: 1px solid #ebedf3;

    button {
      color: #3f4254;
      background-color: #d7dae7;
      padding: 10px 14px;

      transition: background-color 0.2s;

      &:hover {
        background-color: ${shade(0.1, '#d7dae7')};
      }
    }

    button:nth-last-child(1) {
      color: white;
      background-color: #02ffd1;
      padding: 10px 14px;

      margin-left: 8px;

      transition: background-color 0.2s;

      &:hover {
        background-color: ${shade(0.25, '#c0504d')};
      }
    }
  }
`;

export const Background = styled.div<IVisibilityProps>`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 20;

  background-color: rgba(0, 0, 0, 0.1);

  width: 100vw;
  height: 100vh;

  visibility: hidden;
  opacity: 0;

  transition: visibility 0.5s, opacity 0.5s;

  ${props =>
    props.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
