import styled, { css } from 'styled-components';

interface IMoney {
  moneyVisible: boolean;
}

export const Money = styled.div<IMoney>`
  background-color: #02ffd1;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  height: 125px;

  gap: 6px;

  margin-bottom: 20px;

  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    > strong {
      font-size: 22px;
    }

    > span {
      font-size: 18px;

      ${props =>
        !props.moneyVisible &&
        css`
          height: 20px;
          width: 100px;

          background: rgb(159, 159, 159);
          background: linear-gradient(
            90deg,
            rgba(159, 159, 159, 1) 0%,
            rgba(149, 149, 149, 1) 50%,
            rgba(159, 159, 159, 1) 100%
          );
          border-radius: 5px;
        `}
    }
  }
`;
