import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  align-items: center;

  > li {
    font-size: 1.25rem;
    color: #b5b5c3;
    list-style: none;

    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: inherit;
      text-decoration: inherit;

      transition: color 0.2s;

      &:hover {
        color: #c0504d;
      }
    }

    & + li {
      margin-left: 15px;
    }

    &:nth-child(1) {
      font-size: 28px;

      color: #181c32;
    }

    @media (max-width: 1024px) {
      font-size: 1.15rem;
    }
  }
`;
