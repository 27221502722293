import styled from 'styled-components';

export const URLPath = styled.ul`
  display: flex;
  align-items: center;

  > li {
    font-size: 1.25rem;
    color: #b5b5c3;
    list-style: none;

    font-weight: 600;

    a {
      color: inherit;
      text-decoration: inherit;

      transition: color 0.2s;

      &:hover {
        color: #c0504d;
      }
    }

    & + li {
      margin-left: 15px;
    }

    &:nth-child(1) {
      font-size: 28px;

      color: #181c32;
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }
`;

export const CardContent = styled.div`
  padding: 30px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
`;

export const Prospect = styled.div`
  color: #fff;

  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;

  position: relative;

  padding: 10px;

  background-color: #02ffd1;

  border-radius: 8px;

  transition: transform 0.4s;

  box-shadow: 0 0 50px #ebedf3;

  cursor: pointer;

  &:hover {
    transform: translateX(20px);
  }

  &::before {
    content: '';
    position: absolute;

    top: 50%;
    left: 0;

    width: 5px;
    height: 70%;

    background-color: #ff9000;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    transform: translateY(-50%);
  }
`;

export const ProspectIcon = styled.div`
  > svg {
    width: 30px;
    height: 30px;
  }
`;

export const ProspectInfo = styled.div`
  > h1 {
    font-size: 24px;
    font-weight: 600;
  }

  span {
    font-size: 18px;
  }
`;

export const ProspectCreatedAt = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    color: #fff;
    font-weight: 600;
    opacity: 0.8;
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  gap: 20px;
`;
