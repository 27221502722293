import { FC, useCallback, useEffect, useState } from 'react';
import { FiHelpCircle, FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Input } from '@components/elements/Form/Input';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent, FaqCategory } from './styles';
import { IFaqCategory } from './interfaces';

const FaqCategories: FC = () => {
  const { push } = useHistory();

  const [faqCategories, setFaqCategories] = useState<IFaqCategory[]>();
  const [searchString, setSearchString] = useState('');
  const [searchFaqCategories, setSearchFaqCategories] = useState<
    IFaqCategory[]
  >();

  useEffect(() => {
    async function loadCategories() {
      const { data } = await api.get('/faq-categories');

      setFaqCategories(data);
      setSearchFaqCategories(data);
    }

    const timer = setTimeout(() => {
      loadCategories();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleSearchFaqCategories = useCallback(() => {
    const search = faqCategories?.filter(
      item => item.title.toLowerCase().search(searchString.toLowerCase()) >= 0,
    );

    setSearchFaqCategories(search || []);
  }, [faqCategories, searchString]);

  const handleSwitchPage = useCallback(
    (faqCategoryId: string) => {
      push(`/faq-categories/${faqCategoryId}/faqs`);
    },
    [push],
  );

  return (
    <>
      {!searchFaqCategories ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Perguntas frequentes', 'CredCash']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Escolha uma categoria de perguntas</h1>

                <Form onSubmit={handleSearchFaqCategories}>
                  <Input
                    name="search"
                    icon={FiSearch}
                    placeholder="Procurar..."
                    onChange={e => setSearchString(e.target.value)}
                    onBlur={handleSearchFaqCategories}
                  />
                </Form>
              </CardHeader>

              <CardContent>
                {searchFaqCategories.map(item => (
                  <FaqCategory
                    key={item.id}
                    onClick={() => handleSwitchPage(item.id)}
                  >
                    <FiHelpCircle size={40} color="#c0504d" />
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                    <Badge type="info">
                      {item.faqs.length}
                      &nbsp;perguntas
                    </Badge>
                  </FaqCategory>
                ))}
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { FaqCategories };
