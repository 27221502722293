import { FC, useCallback, useRef, useState } from 'react';
import { FiUser, FiLock, FiKey } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';

import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import bbankGroup from '@assets/img/passToPayLogo.png';

import {
  Container,
  Content,
  ButtonLogin,
  Background,
  ImageWrapper,
} from './styles';

interface IFormLoginData {
  document: string;
  password: string;
}

const Login: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { push } = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const handleLoginFormSubmit = useCallback(
    async (data: IFormLoginData) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          document: Yup.string().required('Informe seu CPF/CNPJ'),
          password: Yup.string().required('Informe sua senha'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const logged = await signIn(
          data.document.replace(/[-./]+/g, ''),
          data.password,
        );

        if (logged.type && logged.type === 'inativo') {
          addToast({
            title: 'Cadastro inativo!',
            type: 'info',
            message:
              'O seu cadastro foi desativado. Por favor, entre em contato com a administração do sistema e informe ocorrido!',
          });
        }

        if (logged.type && logged.type === 'pendente') {
          addToast({
            title: 'Cadastro pendente!',
            type: 'info',
            message: 'O seu cadastro ainda não está ativo!',
          });
        }

        if (!logged.ok) {
          setLoading(false);
        }

        if (logged.ok) {
          push('/dashboard');
        }
      } catch (err: any) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 500) {
            addToast({
              title: `Algum erro aconteceu`,
              type: 'error',
              message: 'Contate o administrador do sistema',
            });

            return;
          }

          addToast({
            title: `Email/senha incorretos!`,
            type: 'error',
            message,
          });
        }
      }
    },
    [signIn, push, addToast],
  );

  return (
    <Container>
      <Content>
        <div>
          <ImageWrapper>
            <img src={bbankGroup} alt="Grupo Bbank" />
          </ImageWrapper>

          <Form onSubmit={handleLoginFormSubmit} ref={formRef}>
            <h1>Faça seu login</h1>

            <Input name="document" icon={FiUser} placeholder="CPF/CNPJ" />

            <Input
              type="password"
              name="password"
              icon={FiLock}
              placeholder="Senha"
            />

            <ButtonLogin type="submit">
              {loading ? (
                <Loader type="TailSpin" color="#fff" height={30} width={30} />
              ) : (
                `Entrar`
              )}
            </ButtonLogin>

            <Link to="/forgot-password">
              <FiKey size={20} />
              Esqueci minha senha
            </Link>
          </Form>

          <ul>
            <li>
              {new Date().getFullYear()}
              &copy;
            </li>
            <li>Todos os direitos reservados</li>
          </ul>
        </div>
      </Content>

      <Background />
    </Container>
  );
};

export { Login };
