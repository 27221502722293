import styled from 'styled-components';

export const URLPath = styled.ul`
  display: flex;
  align-items: center;

  > li {
    font-size: 1.25rem;
    color: #b5b5c3;
    list-style: none;

    font-weight: 600;

    a {
      color: inherit;
      text-decoration: inherit;

      transition: color 0.2s;

      &:hover {
        color: #c0504d;
      }
    }

    & + li {
      margin-left: 15px;
    }

    &:nth-child(1) {
      font-size: 28px;

      color: #181c32;
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const InputFileContainer = styled.div`
  flex: 1;

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      color: #3f4254;
      margin-bottom: 5px;
      margin-left: 5px;
      font-weight: 600;
    }

    > span {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #ffa800;
    }
  }
`;

export const InputFile = styled.div`
  height: 250px;
  width: 100%;

  position: relative;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 8%);

  border: 2px dashed #02ffd1;
  border-radius: 10px;

  background-color: #f3f6f9;

  > div {
    height: 100%;
    width: 100%;

    padding: 10px;

    overflow-x: auto;

    z-index: 1;

    display: flex;
    align-items: center;
    gap: 15px;

    > img {
      max-width: 250px;
      height: auto;
    }
  }

  > input {
    opacity: 0;

    height: 92%;
    width: 100%;

    position: absolute;

    top: 0;

    cursor: pointer;

    z-index: 2;
  }

  > span {
    position: absolute;

    font-size: 17px;

    color: #ccc;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
`;
