import styled, { css } from 'styled-components';

interface IContainer {
  isShown: boolean;
}

interface IBackground {
  isShown: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;

  z-index: 13;

  > button {
    background-color: transparent;

    position: relative;

    color: #ccc;

    border-radius: 0.85rem;

    width: 43px;
    height: 43px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;

    cursor: pointer;

    ${props =>
      props.isShown &&
      css`
        background-color: #f3f6f9;

        > svg {
          color: #02ffd1;
        }
      `}

    transition: background-color 0.2s;

    &:hover {
      background-color: #f3f6f9;

      > svg {
        color: #02ffd1;
      }
    }

    > svg {
      width: 25px;
      height: 25px;

      transition: color 0.2s;
    }
  }

  > div {
    position: absolute;
    right: 0;

    background-color: white;

    width: 500px;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    border-radius: 10px;

    padding: 20px 30px;

    margin-top: 5px;

    transition: visibility 0.4s, opacity 0.4s;

    ${props =>
      props.isShown
        ? css`
            opacity: 1;
            visibility: visible;
          `
        : css`
            opacity: 0;
            visibility: hidden;
          `}

    > header {
      border-bottom: 2px solid #e1e1e1;

      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 7px;

      > h1 {
        font-size: 2rem;

        color: #02ffd1;
      }

      > button {
        width: 35px;
        height: 35px;

        background-color: transparent;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: background-color 0.2s;

        &:hover {
          background-color: #f3f6f9;

          > svg {
            color: #02ffd1;
          }
        }

        > svg {
          color: #ccc;

          width: 20px;
          height: 20px;

          transition: color 0.2s;
        }
      }
    }

    > main {
      border-bottom: 2px solid #e1e1e1;
      padding-bottom: 7px;

      max-height: 60vh;

      overflow: auto;
    }

    > footer {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 12px;

      > span {
        color: #ccc;
      }
    }
  }
`;

export const Background = styled.div<IBackground>`
  position: absolute;

  background-color: rgba(0, 0, 0, 0);

  height: 100vh;
  width: 100vw;

  top: 0;
  left: 0;

  z-index: 12;

  ${props =>
    props.isShown
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  transition: visibility 0.4s, opacity 0.4s;
`;

export const Counter = styled.span`
  font-size: 14px;

  background-color: #02ffd1;

  color: white;

  position: absolute;

  bottom: calc(100% - 20px);
  left: calc(100% - 20px);

  border-radius: 50%;

  width: 20px;
  height: 20px;
`;
