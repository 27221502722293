import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { TableColumn } from 'react-data-table-component';
import {
  FiDollarSign,
  FiDownload,
  FiEye,
  FiMoreHorizontal,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import exportFromJSON from 'export-from-json';
import { format } from 'date-fns';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { Dropdown } from '@components/elements/Dropdown';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { maskHandler } from '@helpers/maskHandler';

import {
  Card,
  CardHeader,
  CardContent,
  TableButtonContainer,
  TableButton,
} from './styles';
import { IMerchant, IMerchantFromApi } from './interfaces';

const ListMerchants: FC = () => {
  const { push } = useHistory();

  const [merchants, setMerchants] = useState<IMerchant[]>();

  useEffect(() => {
    async function loadMerchants() {
      const { data } = await api.get<IMerchantFromApi[]>(
        `/bbank-pay/merchants`,
      );

      const parsedData = data.map(merchant => {
        return parseObjectPropertiesToCamelCase<IMerchant>(merchant);
      });

      setMerchants(parsedData);
    }

    const timeout = setTimeout(() => {
      loadMerchants();
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'CNPJ do estabelecimento',
        selector: 'search',
      },
      {
        name: 'Nome fantasia',
        selector: 'nameFantasy',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Infinity ID',
        selector: 'infinityId',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!merchants) {
      return [];
    }

    const data = merchants.map(merchant => {
      let badgeType: 'danger' | 'success' | 'warning' = 'success';

      if (merchant.status === 'parado') {
        badgeType = 'danger';
      }

      if (merchant.status === 'pendente') {
        badgeType = 'warning';
      }

      return {
        id: merchant.id,
        search: merchant.cnpj,
        infinityId: merchant.infinityId,
        nameFantasy: merchant.nameFantasy,
        status: <Badge type={badgeType}>{merchant.status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Visualizar"
              onClick={() => push(`/bbank-pay/merchants/view/${merchant.id}`)}
            >
              <FiEye size={20} />
            </TableButton>
            <TableButton
              styleType="info"
              title="Transações"
              onClick={() =>
                push(`/bbank-pay/merchants/${merchant.id}/transactions`)
              }
            >
              <FiDollarSign size={20} />
            </TableButton>
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [merchants, push]);

  const handleExportData = useCallback(() => {
    if (!merchants) {
      return;
    }

    const merchantsLength = merchants.length;

    const numberFormatter = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const worksheetData = Array.from(Array(merchantsLength).keys()).map(
      item => {
        const merchant = merchants[item];

        const worksheetLines = {
          CNPJ: maskHandler({ mask: 'cnpj', payload: merchant.cnpj }),
          'Nome fantasia': merchant.nameFantasy,
          'Razão social': merchant.socialReason,
          'Nome do cliente': merchant.client.name,
          'Nome da indicação': merchant.franchisee.name,
          'Nome do responsável': merchant.responsibleName,
          'Email do responsável': merchant.responsibleEmail,
          'CPF do responsável': maskHandler({
            mask: 'cpf',
            payload: merchant.responsibleCpf,
          }),
          'Data de aniversário do responsável': format(
            new Date(merchant.responsibleBirthday),
            'dd/MM/yyyy',
          ),
          'Celular do responsável': maskHandler({
            mask: 'cellphone',
            payload: merchant.responsibleCellphone,
          }),
          'Código postal': maskHandler({
            mask: 'cep',
            payload: merchant.zipcode,
          }),
          Endereço: merchant.address,
          Bairro: merchant.neighborhood,
          Cidade: merchant.city,
          Estado: merchant.state,
          Complemento: merchant.complement,
          'Número do enredeço': merchant.addressNumber,
          'Método de negócio': merchant.businessMethod,
          'Tipo de venda': merchant.sellingType,
          'Valor do pagamento': numberFormatter(merchant.paymentValue),
          'Forma de pagamento': merchant.paymentForm,
          'Parcelas de pagamento': merchant.paymentPortions,
          'Porcentagem de cacheback': `${merchant.cashbackPercentage}%`,
        };

        return worksheetLines;
      },
    );

    exportFromJSON({
      data: worksheetData,
      exportType: 'xls',
      fileName: 'estabelecimentos_pj',
    });
  }, [merchants]);

  return (
    <>
      {!merchants ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['BbankPay', 'Estabelecimentos', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimentos</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#c0504d' }}
                  icon={FiMoreHorizontal}
                  isDisabled={merchants.length === 0}
                >
                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar dados para Excel
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por CNPJ..."
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListMerchants };
