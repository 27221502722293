import styled, { css, keyframes } from 'styled-components';

interface IBottomSheet {
  visible?: boolean;
}

const animationFromBottom = keyframes`
  0% {
    transform: translateY(100px);
  };

  100% {
    transform: translateY(0);
  };
`;

const animationToBottom = keyframes`
  100% {
    transform: translateY(100px);
  };

  0% {
    transform: translateY(0);
  };
`;

export const RemoveElementButton = styled.button`
  color: #f64e60;

  font-size: 14px;
  font-weight: 400;
`;

export const BottomSheet = styled.div<IBottomSheet>`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  background-color: #02ffd1;

  border-top: 1px solid #02ffd1;

  color: #fff;

  padding: 10px 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  transform: translateY(100px);

  ${props => {
    if (props.visible === true) {
      return css`
        animation: 500ms ${animationFromBottom} forwards;
      `;
    }

    if (props.visible === false) {
      return css`
        animation: 500ms ${animationToBottom} forwards;
      `;
    }
  }};

  > header {
    margin-right: 12px;

    > h1 {
      font-size: 2rem;
    }
  }

  > main {
    > span:nth-child(1) {
      font-size: 1.75rem;

      margin-right: 3px;
    }

    > span:nth-child(2) {
      font-size: 1.5rem;
    }
  }

  > footer {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    > span {
      & + * {
        margin-left: 6px;
      }

      font-size: 1.5rem;
    }
  }
`;
