import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { FiMoreHorizontal, FiPlusCircle, FiTrash } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { consoleLog } from '@helpers/consoleLog';

import { ISliders } from './interfaces';

export const ListSliders: FC = () => {
  const [news, setNews] = useState<ISliders[]>();

  useEffect(() => {
    async function loadListSliders() {
      const { data } = await api.get<any[]>('/galleries');
      consoleLog(data);

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<ISliders>(item),
      );

      setNews(parsedData);
    }

    const timer = setTimeout(() => loadListSliders(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleDeleteSlider = useCallback(async (galleryId: string) => {
    await api.delete(`/galleries/${galleryId}`);

    consoleLog(galleryId);
    setNews(oldState => {
      if (!oldState) {
        return [];
      }

      const updatedState = oldState.filter(gallery => gallery.id !== galleryId);

      return updatedState;
    });
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da noticia',
        selector: 'name',
      },
      {
        name: 'Data de criação',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!news) {
      return [];
    }

    const data = news.map(slider => {
      return {
        id: slider._id,
        name: slider.name,
        createdAt: format(new Date(slider.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="danger"
              onDoubleClick={() => {
                handleDeleteSlider(slider.Id);
              }}
              title="Clique duas vezes para deletar!"
              icon={FiTrash}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [news, handleDeleteSlider]);

  return (
    <>
      {!news ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <Card>
              <CardHeader>
                <h1>Nova imagem sliders</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#c0504d' }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <Link to="/news/create">
                      <FiPlusCircle />
                      Nova Noticia
                    </Link>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
