import styled, { css } from 'styled-components';

import { Tooltip as TooltipComponent } from '@components/elements/Tooltip';

interface IContainer {
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<IContainer>`
  flex: 1;

  position: relative;

  display: flex;
  align-items: center;

  padding: 0 10px;

  background-color: #f3f6f9;

  border: 2px solid transparent;
  border-radius: 10px;

  height: 45px;

  > svg {
    margin-right: 16px;

    color: #ccc;

    ${props =>
      props.isFilled &&
      css`
        color: #c0504d;
      `}
  }

  > input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  > span {
    color: #ccc;
    font-weight: 600;
    font-size: 16px;

    ${props =>
      props.isFilled &&
      css`
        color: #181c32;
      `}
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
`;

export const Tooltip = styled(TooltipComponent)`
  position: absolute;
  top: 50%;
  right: 15px;

  transform: translateY(-50%);
`;
