import { FormHandles } from '@unform/core';
import { format } from 'date-fns/esm';
import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FiDollarSign,
  FiMinusCircle,
  FiMoreHorizontal,
  FiPlusCircle,
} from 'react-icons/fi';
import { v4 } from 'uuid';
import { ValidationError } from 'yup';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Button } from '@components/elements/Button';
import { Dropdown } from '@components/elements/Dropdown';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';
import { IZipcodeResponse } from '@services/interfaces';

import { consoleLog } from '@helpers/consoleLog';
import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { statesOptions } from '@pages/private/shared/CompleteMyInfo/selectOptions';

import { IClient, IDescription, IDiscount, IFormData } from './interface';
import { getClientOldOptions, getClientOptions } from './selectOpitions';
import { CardSelectOpcions } from './styles';
import { formValidation } from './validations';

// import { v4 } from 'uuid';
// import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
const NewBillet: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [clients, setClients] = useState<IClient[]>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { addToast } = useToast();
  const [descriptions, setDescriptions] = useState<IDescription[]>([]);
  const [discounts, setDiscounts] = useState<IDiscount[]>([]);

  useEffect(() => {
    async function loadClients() {
      const { data } = await api.get('/users-indicated');

      setClients(data);
    }

    const timer = setTimeout(() => {
      loadClients();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    async function loadOldClients() {
      const { data } = await api.get('/billet-clients');

      setOldClients(data);
    }

    const timer = setTimeout(() => {
      loadOldClients();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const handleAddMessages = useCallback(() => {
    setDescriptions(oldState => {
      if (oldState.length === 7) {
        return oldState;
      }

      const message = {
        id: v4(),
      };

      return [...oldState, message];
    });
  }, []);

  const handleRemoveDescription = useCallback((messageId: string) => {
    setDescriptions(oldState => {
      const updatedState = oldState.filter(state => state.id !== messageId);

      return updatedState;
    });
  }, []);

  const handleAddDiscount = useCallback(() => {
    setDiscounts(oldState => {
      if (oldState.length === 7) {
        return oldState;
      }

      const message = {
        id: v4(),
      };

      return [...oldState, message];
    });
  }, []);

  const handleRemoveDiscounts = useCallback((id: string) => {
    setDiscounts(oldState => {
      const updatedState = oldState.filter(state => state.id !== id);

      return updatedState;
    });
  }, []);

  const [selectedOption, setSelectedOption] = useState('');
  const [oldClients, setOldClients] = useState<any>();

  const [selectedOldClient, setSelectedOldClient] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    document: '',
    zipcode: '',
    address: '',
    city: '',
    state: '',
  });

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data, selectedOption);

        setFormData(prevFormData => ({
          ...prevFormData,
          state: data.state,
        }));

        await api.post('/billets', {
          dueDate: data.dueDate,
          issueDate: data.issueDate,
          payerId: data.payerId,
          paymentValue: data.paymentValue,
          discounts: data.discounts,
          description: data.descriptions,
          billetClientId: data.billetClientId,

          address: formData.address,
          city: formData.city,
          document: formData.document,
          name: formData.name,
          state: data.state,
          zipcode: formData.zipcode,
        });

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'O seu boleto foi gerado com sucesso!',
        });
      } catch (err: any) {
        consoleLog(err);

        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          console.log(errors);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status >= 400 && status < 499) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [
      addToast,
      selectedOption,
      formData.address,
      formData.city,
      formData.document,
      formData.name,
      formData.zipcode,
    ],
  );

  const clientOptions = useMemo(() => {
    return clients ? getClientOptions(clients) : [];
  }, [clients]);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const clientOldOptions = useMemo(() => {
    return oldClients ? getClientOldOptions(oldClients) : [];
  }, [oldClients]);

  const handleOldClientSelect = (selectedClient: any) => {
    if (!selectedClient) return;

    setSelectedOldClient(selectedClient);

    const clientData = oldClients.find(
      (client: any) => client.id === selectedClient.value,
    );

    if (clientData) {
      const { name, document, zipcode, address, city, state } = clientData;
      setFormData({ name, document, zipcode, address, city, state });
      formRef.current?.setFieldValue('state', state);
    }
  };

  const handleFindZipcode = useCallback(
    async (zipcode: string) => {
      try {
        const { data } = await viaCepApi.get<IZipcodeResponse>(
          `/${zipcode}/json`,
        );
        const zipcodeInputRef = formRef.current?.getFieldRef('zipcode');
        const cityInputRef = formRef.current?.getFieldRef('city');
        const addressInputRef = formRef.current?.getFieldRef('address');
        const ufInputRef = formRef.current?.getFieldRef('state');

        if (data.erro === true) {
          zipcodeInputRef.value = '';
          cityInputRef.value = '';
          addressInputRef.value = '';
          ufInputRef.value = '';
          return;
        }

        const { cep, localidade, logradouro, uf } = data;

        zipcodeInputRef.value = cep;
        cityInputRef.value = localidade;
        addressInputRef.value = logradouro;
        ufInputRef.value = uf;

        console.log(logradouro);

        const stateInputRef = formRef.current?.getFieldRef('state');

        stateInputRef.focus();
      } catch (err: any) {
        if (err.response) {
          addToast({
            title: 'Algo não está certo!',
            type: 'error',
            message: 'O CEP informado não foi digitado corretamente!',
          });
        }
      }
    },
    [addToast],
  );

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <AuthModal />

          <Row>
            <URLPath paths={['Boleto', 'Novo Boleto']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo Boleto</h1>

                <Dropdown
                  styles={{
                    textColor: '#fff',
                    togglerBackground: '#02ffd1',
                  }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button type="button" onClick={handleAddMessages}>
                      <FiPlusCircle />
                      Adicionar nova descrição
                    </button>
                  </li>

                  <li>
                    <button type="button" onClick={handleAddDiscount}>
                      <FiDollarSign />
                      Adicionar novo desconto
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow separator>
                    <h1>Preencha os dados para gerar o boleto!</h1>

                    <CardSelectOpcions>
                      <div>
                        <label htmlFor="newClient">Novo Cliente</label>
                        <input
                          id="newClient"
                          type="radio"
                          name="clientType"
                          value="new"
                          onChange={handleOptionChange}
                        />
                      </div>

                      <div>
                        <label htmlFor="selectClient">Selecionar Cliente</label>
                        <input
                          id="selectClient"
                          type="radio"
                          name="clientType"
                          value="select"
                          onChange={handleOptionChange}
                        />
                      </div>
                    </CardSelectOpcions>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor do boleto</label>
                      <InputCurrency name="paymentValue" />
                    </InputGroup>

                    {selectedOption === 'select' && (
                      <InputGroup>
                        <label>Selecione o cliente</label>
                        <Select name="payerId" options={clientOptions} />
                      </InputGroup>
                    )}
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de emissão</label>
                      <Input
                        name="issueDate"
                        defaultValue={format(new Date(), 'dd/MM/yyyy')}
                        readOnly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de vencimento</label>
                      <InputMask mask="99/99/9999" name="dueDate" noUnmask />
                    </InputGroup>
                  </FormRow>

                  {descriptions.length > 0 && (
                    <>
                      <FormRow separator>
                        <h1>Descrições</h1>
                      </FormRow>

                      {descriptions.map((description, index) => (
                        <div
                          key={description.id}
                          style={{ display: 'flex', flex: 1, marginTop: 20 }}
                        >
                          <FormRow>
                            <InputGroup>
                              <label>Texto</label>
                              <Input
                                name={`descriptions[${index}].text`}
                                maxLength={255}
                                upperCase={false}
                              />
                            </InputGroup>

                            <InputGroup>
                              <label>Preço (opcional)</label>
                              <InputCurrency
                                name={`descriptions[${index}].amount`}
                              />
                            </InputGroup>
                          </FormRow>

                          <Button
                            styleType="danger"
                            icon={FiMinusCircle}
                            onClick={() =>
                              handleRemoveDescription(description.id)
                            }
                            style={{
                              padding: 11,
                              marginLeft: 6,
                              alignSelf: 'flex-end',
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}

                  {discounts.length > 0 && (
                    <>
                      <FormRow separator>
                        <h1>Descontos</h1>
                      </FormRow>

                      {discounts.map((message, index) => (
                        <div
                          key={message.id}
                          style={{ display: 'flex', flex: 1, marginTop: 20 }}
                        >
                          <FormRow>
                            <InputGroup>
                              <label>Porcentagem do desconto</label>
                              <Input
                                name={`discounts[${index}].percentage`}
                                maxLength={255}
                                upperCase={false}
                              />
                            </InputGroup>

                            <InputGroup>
                              <label>Data do desconto</label>
                              <InputMask
                                name={`discounts[${index}].date`}
                                mask="99/99/9999"
                                noUnmask
                              />
                            </InputGroup>
                          </FormRow>

                          <Button
                            styleType="danger"
                            icon={FiMinusCircle}
                            onClick={() => handleRemoveDiscounts(message.id)}
                            style={{
                              padding: 11,
                              marginLeft: 6,
                              alignSelf: 'flex-end',
                            }}
                          />
                        </div>
                      ))}
                    </>
                  )}

                  {selectedOption === 'new' && (
                    <>
                      <FormRow separator style={{ padding: '10px 0' }}>
                        <h1>Novo Cliente</h1>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 'revert',
                            width: '50%',
                            justifyContent: 'end',
                          }}
                        >
                          <Select
                            name="billetClientId"
                            options={clientOldOptions}
                            onChange={handleOldClientSelect}
                          />
                        </div>
                      </FormRow>

                      <FormRow>
                        <InputGroup>
                          <label>Nome</label>
                          <Input
                            name="name"
                            value={formData.name}
                            onChange={e =>
                              setFormData({ ...formData, name: e.target.value })
                            }
                          />
                        </InputGroup>

                        <InputGroup>
                          <label>Documento</label>
                          <Input
                            name="document"
                            value={formData.document}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                document: e.target.value,
                              })
                            }
                          />
                        </InputGroup>
                      </FormRow>

                      <FormRow>
                        <InputGroup>
                          <label>CEP</label>
                          <Input
                            name="zipcode"
                            value={formData.zipcode}
                            onBlur={e => handleFindZipcode(e.target.value)}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                zipcode: e.target.value,
                              })
                            }
                          />
                        </InputGroup>

                        <InputGroup>
                          <label>Endereço</label>
                          <Input
                            name="address"
                            value={formData.address}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                address: e.target.value,
                              })
                            }
                          />
                        </InputGroup>

                        <InputGroup>
                          <label>Cidade</label>
                          <Input
                            name="city"
                            value={formData.city}
                            onChange={e =>
                              setFormData({ ...formData, city: e.target.value })
                            }
                          />
                        </InputGroup>

                        <InputGroup>
                          <label>Estado</label>
                          <Select
                            name="state"
                            placeholder="Estado"
                            options={statesOptions}
                            // onChange={(e: any) =>
                            //   setFormData({
                            //     ...formData,
                            //     state: e.target.value,
                            //   })
                            // }
                          />
                        </InputGroup>
                      </FormRow>
                    </>
                  )}

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button
                      type="submit"
                      styleType="info"
                      loading={loadingRequest}
                    >
                      Gerar boleto!
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewBillet };
