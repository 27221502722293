import {
  FC,
  InputHTMLAttributes,
  ComponentType,
  useState,
  useCallback,
  useRef,
  useEffect,
  FocusEvent,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { Container } from './styles';
import { Tooltip } from '../../Tooltip';

interface IInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'autoComplete'> {
  icon?: ComponentType<IconBaseProps>;
  upperCase?: boolean;
  name: string;
}

const Input: FC<IInputProps> = ({
  name,
  icon: Icon,
  style,
  disabled,
  onBlur,
  onFocus,
  readOnly,
  upperCase = true,
  defaultValue: defaultValueFromProps,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFosused] = useState(false);
  const [passwordButtonShown, setPasswordButtonShown] = useState(false);
  const [inputType, setInputType] = useState(rest.type || 'text');
  const [isFilled, setIsFilled] = useState(
    !!inputRef.current?.value || !!defaultValueFromProps,
  );

  const { fieldName, registerField, error, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: (ref: any) => {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';
        setIsFilled(false);
      },
      setValue: (ref: any, value: string) => {
        // eslint-disable-next-line no-param-reassign
        ref.value = value;
        setIsFilled(true);
      },
    });
    setIsFilled(!!inputRef.current?.value || !!defaultValueFromProps);
  }, [registerField, fieldName, defaultValueFromProps]);

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setIsFosused(true);

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }

      setIsFilled(!!inputRef.current?.value);
      setIsFosused(false);

      if (
        rest.type !== 'email' &&
        upperCase &&
        rest.type !== 'password' &&
        !readOnly
      ) {
        const ref = inputRef.current as any;

        ref.value = ref.value.toUpperCase();
      }
    },
    [onBlur, rest.type, upperCase, readOnly],
  );

  const handlePasswordButtonShown = useCallback(() => {
    if (inputType === 'password') {
      setInputType('text');
      setPasswordButtonShown(true);
    } else {
      setInputType('password');
      setPasswordButtonShown(false);
    }
  }, [inputType]);

  return (
    <Container
      isFocused={isFocused}
      isFilled={isFilled}
      hasErrors={!!error}
      style={style}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isHidden={inputType === 'hidden'}
    >
      {Icon && <Icon size={20} />}
      <input
        defaultValue={defaultValueFromProps || defaultValue}
        name={name}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={inputRef}
        readOnly={readOnly}
        disabled={disabled}
        autoComplete="off"
        {...rest}
        type={inputType}
      />
      {rest.type === 'password' && (
        <button
          type="button"
          onClick={handlePasswordButtonShown}
          style={{ marginRight: error ? 6 : 0 }}
        >
          {passwordButtonShown ? (
            <FiEyeOff size={20} color="#c0504d" />
          ) : (
            <FiEye size={20} color="#ccc" />
          )}
        </button>
      )}
      {!!error && <Tooltip type="error">{error}</Tooltip>}
    </Container>
  );
};

export { Input };
