import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { FiDownload, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import exportFromJSON from 'export-from-json';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';
import { Badge } from '@components/elements/Badge';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IInvestment } from './interfaces';

const ListInvestmentsByStatus: FC = () => {
  const { push } = useHistory();
  const { user } = useAuth();
  const { search } = useLocation();

  const query = search.replace('?status=', '');

  const [investments, setInvestments] = useState<IInvestment[]>();

  useEffect(() => {
    setInvestments(undefined);

    async function loadInvestments() {
      const { data } = await api.get<any[]>('/investments/client', {
        params: {
          relations: ['investment_percentage'],
        },
      });

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IInvestment>(item);
      });

      const filtered = parsedData.filter(item => item.status === query);

      setInvestments(filtered);
    }

    const timer = setTimeout(() => loadInvestments(), 1500);

    return () => clearInterval(timer);
  }, [query]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do cliente',
        selector: 'search',
      },
      {
        name: 'Duração',
        selector: 'duration',
      },
      {
        name: 'Percentual',
        selector: 'percentage',
      },
      {
        name: 'Valor',
        selector: 'value',
      },
      {
        name: 'Data início',
        selector: 'dateInit',
      },
      {
        name: 'Data fim',
        selector: 'dateEnd',
      },
      {
        name: 'Status',
        selector: 'status',
      },
    ];
  }, []);

  const statusNames = useMemo<any>(() => {
    return {
      pending: {
        text: 'Pendente',
        badgeType: 'warning',
      },
      active: {
        text: 'Ativo',
        badgeType: 'success',
      },
      contract_sent: {
        text: 'Contrato gerado',
        badgeType: 'info',
      },
      canceled: {
        text: 'Cancelado',
        badgeType: 'danger',
      },
    };
  }, []);

  const tableData = useMemo(() => {
    if (!investments) {
      return [];
    }

    const data = investments.map(investment => ({
      id: investment.id,
      search: user.name,
      duration: `${investment.investmentPercentage.period} meses`,
      percentage: `${investment.investmentPercentage.percentage}%`,
      value: new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format(investment.investmentPercentage.value),
      dateInit: format(new Date(investment.dateInit), 'dd/MM/yyyy'),
      dateEnd: format(new Date(investment.dateEnd), 'dd/MM/yyyy'),
      status: (
        <Badge type={statusNames[investment.status].badgeType}>
          {statusNames[investment.status].text}
        </Badge>
      ),
    }));

    return data;
  }, [investments, user, statusNames]);

  const handlePushToAddPage = useCallback(() => {
    push('/shared/bbank-invest/investments/new');
  }, [push]);

  const handleExportData = useCallback(() => {
    if (!investments) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const investmentsLength = investments.length;

    const worksheetData = Array.from(Array(investmentsLength).keys()).map(
      item => {
        const investment = investments[item];

        const worksheetLines = {
          Recorrência: numberFormat(investment.investmentPercentage.income),
          Porcentagem: `${investment.investmentPercentage.percentage}%`,
          Período: `${investment.investmentPercentage.period} meses`,
          Valor: numberFormat(investment.investmentPercentage.value),
          'Data de início': format(new Date(investment.dateInit), 'dd/MM/yyyy'),
          'Data final': format(new Date(investment.dateEnd), 'dd/MM/yyyy'),
          'Nome do cliente': investment.client.name,
        };

        return worksheetLines;
      },
    );

    exportFromJSON({
      data: worksheetData,
      exportType: 'xls',
      fileName: 'dados_investimentos',
    });
  }, [investments]);

  return (
    <>
      {!investments ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Bbank Invest', 'Investimentos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Ver todos os investimentos feitos</h1>

                <Dropdown
                  styles={{ togglerBackground: '#c0504d', textColor: '#fff' }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button type="button" onClick={handlePushToAddPage}>
                      <FiPlusCircle />
                      Novo investimento
                    </button>
                  </li>

                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar dados
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListInvestmentsByStatus };
