import { ComponentType, FC, useMemo } from 'react';
import {
  Route as RouteDOM,
  RouteProps as RouteDOMProps,
} from 'react-router-dom';
import Loader from 'react-loader-spinner';

import { useAuth } from '@hooks/auth';

import { Login } from '@pages/public/Login';
import { Dashboard } from '@pages/private/shared/Dashboard';
import { NotFound } from '@pages/errors/NotFound';
import { Unauthorized } from '@pages/errors/Unauthorized';
import { CompleteMyInformation } from '@pages/private/shared/CompleteMyInfo';

interface IRouteProps extends RouteDOMProps {
  isPrivate?: boolean;
  freePass?: boolean;
  grantAccess?: Array<string>;
  component?: ComponentType;
}

const Route: FC<IRouteProps> = ({
  isPrivate,
  grantAccess,
  freePass,
  component: Component,
  render,
  ...rest
}) => {
  const { user, loading, completedData } = useAuth();

  const access = useMemo(() => {
    if (!user) {
      return;
    }

    const { role } = user;

    return grantAccess?.find(item => item === role);
  }, [user, grantAccess]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Loader type="TailSpin" width={60} height={60} color="#c0504d" />
      </div>
    );
  }

  return (
    <RouteDOM
      {...rest}
      render={
        !render
          ? () => {
              if (Component) {
                if (freePass && isPrivate && !user) {
                  return <Login />;
                }

                if (freePass && !isPrivate && !user) {
                  return <Component />;
                }

                if (isPrivate && !user) {
                  return <Login />;
                }

                if (!completedData && user && isPrivate) {
                  return <CompleteMyInformation />;
                }

                if (freePass && isPrivate && user) {
                  return <Component />;
                }

                if (freePass && !isPrivate && user) {
                  return <Component />;
                }

                if (!isPrivate && !user) {
                  return <Component />;
                }

                if (!isPrivate && user) {
                  return <Dashboard />;
                }

                if (isPrivate && user && access) {
                  return <Component />;
                }

                if (isPrivate && user && !access) {
                  return <Unauthorized />;
                }

                return <Component />;
              }

              return <NotFound />;
            }
          : render
      }
    />
  );
};

export { Route };
